import './App.css';
// import React, { useState, useRef } from 'react'; // Don't forget to import useRef
import RandomQuote from './quoteComp';
// import BrickBreaker from './brickBreak';
// import BrickBreaker2 from './brickBreak2';

function App() {
  // const [showGame, setShowGame] = useState(false);
  // const gameRef = useRef(null);

  // const handleClick = () => {
  //   setShowGame(!showGame); // Toggle the showGame state
  //   if (!showGame && gameRef.current) { // Check if the game is about to be shown
  //     gameRef.current.scrollIntoView( { behavior: 'smooth' } );
  //   }
  // };

  // console.log(gameRef)
  return (
    <>
    <div className='d-flex justify-content-center'>
    <div id='under'>
      <p>This site is under construction. Please come back soon!</p>
    </div>
    </div>
      <div id="coming-soon">
        <p>In the mean time here's something to get you thinking:</p>
      </div>
      <div>
        <div>
          <RandomQuote />
        </div>
        {/* <div>
          <button className='drk-btn game-btn' onClick={handleClick}>
            {showGame ? 'Quit Game' : 'Play Brick Breaker'}
          </button>
          <div ref={gameRef} className='game'>
            {showGame && <BrickBreaker2/>}
          </div>
        </div> */}
      </div>
    </>
  );
}

export default App;
