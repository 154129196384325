// quotes.js
export const quotes = [
    "He who jumps into the void owes no explanation to those who stand and watch -Jean-Luc Godard",
    "Happiness was never important. The problem is that we dont know what we really want. What makes us happy is not to get what we want. But to dream about it. Happiness is for opportunists. So I think that the only life of deep satisfaction is a life of eternal struggle, especially struggle within oneself. If you want to remain happy, just remain stupid [ignorant]. Authentic masters are never happy; happiness is a category of slaves. -Žižek ",
    "God is dead. God remains dead. And we have killed him. Nietzsche",
    "The unfed mind devours itself. Gore Vidal",
    "And justly so: for all things, from the Void Called forth, deserve to be destroyed: Twere better, then, were naught created. -Goeth",
    "Find what you love and let it kill you. -Bukowski",
    "Man is the only creature that consumes without producing. He does not give milk, he does not lay eggs, he is too weak to pull a plough, he cannot run fast enough to catch rabbits. Yet he is the lord of all the animals. He sets them to work, he gives back to them the bare minimum that will prevent them from starving, and the rest he keeps for himself -George Orwell",
    "It is the mark of an educated mind to be able to entertain a thought without accepting it. -Aristotle",
    "Why dont you make your bed? The same reason i dont tie my shoes after taking them off.”  Jim Gaffigan",
    "Behind need and want is to be found at once boredom, which attacks even the more intelligent animals. This is the consequence of the fact that life has no genuine intrinsic worth, but is kept in motion merely by want and illusion. But as soon as this comes to a standstill, the utter barrenness and emptiness of existence becomes apparent. -Schopenhauer ",
    "Talent hits a target no one else can hit; Genius hits a target no one else can see. -Arthur Schopenhauer",
    "All truth passes through three stages. First, it is ridiculed. Second, it is violently opposed. Third, it is accepted as being self-evident. -Arthur Schopenhauer",
    "Compassion is the basis of morality. -Arthur Schopenhauer",
    "A man can be himself only so long as he is alone; and if he does not love solitude, he will not love freedom; for it is only when he is alone that he is really free. -Arthur Schopenhauer",
    "The most thought-provoking thing in our thought-provoking time is that we are still not thinking. -Martin Heidegger",
    "Language is the house of Being. In its home man dwells. -Martin Heidegger",
    "To think is to confine yourself to a single thought that one day stands still like a star in the world's sky. -Martin Heidegger",
    "Man acts as though he were the shaper and master of language, while in fact language remains the master of man. -Martin Heidegger",
    "Why are there beings at all, instead of Nothing? -Martin Heidegger",
    "The basic impulse underlying philosophizing is, and always has been, the need to comprehend our own existence and the nature of the world we live in. -Bryan Magee",
    "The ultimate test of our philosophy is not in what it says but in what it does. -Bryan Magee",
    "Philosophy is not a body of doctrine but an activity. -Bryan Magee",
    "The only ultimate disaster that can befall us, I have come to realize, is to feel ourselves to be at home here on Earth. -Bryan Magee",
    "The most important questions are those that will never be answered. -Bryan Magee",
    "Few new truths have ever won their way against the resistance of established ideas save by being overstated. -Isaiah Berlin",
    "The very desire for guarantees that our values are eternal and secure in some objective heaven is perhaps only a craving for the certainties of childhood. -Isaiah Berlin",
    "To understand is to perceive patterns. -Isaiah Berlin",
    "The fundamental sense of freedom is freedom from chains, from imprisonment, from enslavement by others. The rest is extension of this sense, or else metaphor. -Isaiah Berlin",
    "Only barbarians are not curious about where they come from, how they came to be where they are, where they appear to be going, whether they wish to go there, and if so, why, and if not, why not. -Isaiah Berlin",
    "The limits of my language mean the limits of my world. -Ludwig Wittgenstein",
    "Whereof one cannot speak, thereof one must be silent. -Ludwig Wittgenstein",
    "Philosophy is a battle against the bewitchment of our intelligence by means of language. -Ludwig Wittgenstein",
    "The human body is the best picture of the human soul. -Ludwig Wittgenstein",
    "If a lion could talk, we could not understand him. -Ludwig Wittgenstein",
    "The world of the happy is quite different from the world of the unhappy. -Ludwig Wittgenstein",
    "What can be shown, cannot be said. -Ludwig Wittgenstein",
    "The world is everything that is the case. -Ludwig Wittgenstein",
    "Don't get involved in partial problems, but always take flight to where there is a free view over the whole single great problem, even if this view is still not a clear one. -Ludwig Wittgenstein",
    "A serious and good philosophical work could be written consisting entirely of jokes. -Ludwig Wittgenstein",
    "What is your aim in philosophy? To show the fly the way out of the fly-bottle. -Ludwig Wittgenstein",
    "The real discovery is the one which enables me to stop doing philosophy when I want to. -Ludwig Wittgenstein",
    "If people did not sometimes do silly things, nothing intelligent would ever get done. -Ludwig Wittgenstein",
    "A man will be imprisoned in a room with a door that's unlocked and opens inwards; as long as it does not occur to him to pull rather than push. -Ludwig Wittgenstein",
    "Knowledge is in the end based on acknowledgement. -Ludwig Wittgenstein",
    "The meaning of a word is its use in the language. -Ludwig Wittgenstein",
    "Death is not an event in life: we do not live to experience death. -Ludwig Wittgenstein",
    "To imagine a language is to imagine a form of life. -Ludwig Wittgenstein",
    "Philosophy is not a theory but an activity. -Ludwig Wittgenstein",
    "Nothing is so difficult as not deceiving oneself. -Ludwig Wittgenstein",
    "we cannot reason ourselves out of out basic irrationality. All we can do is learn the art of being irrational in a reasonable way. Aldous Huxley"
];
