import React, { useState } from 'react';
import { quotes } from './quotes';

function RandomQuote() {
  const [remainingQuotes, setRemainingQuotes] = useState([...quotes]);
  const [quote, setQuote] = useState(getRandomQuote(remainingQuotes));

  function getRandomQuote(quotesArray) {
    const randomIndex = Math.floor(Math.random() * quotesArray.length);
    return quotesArray[randomIndex];
  }

  const handleNewQuoteClick = () => {
    if (remainingQuotes.length === 1) {
      setRemainingQuotes([...quotes]); // Reset if all quotes have been shown
    } else {
      const newRemainingQuotes = remainingQuotes.filter(q => q !== quote);
      setRemainingQuotes(newRemainingQuotes);
      setQuote(getRandomQuote(newRemainingQuotes));
    }
  };

  return (
    <>
    <div id='quote'>
      <p>{quote}</p>
    </div>
    <div className='d-flex justify-content-center btn-position'>
        <button className='drk-btn' onClick={handleNewQuoteClick}>Get Another Quote</button>
    </div>
    </>
  );
}

export default RandomQuote;
